import p1 from './../assets/ph/ph1.jpg'
import p2 from './../assets/ph/ph2.jpg'
import p3 from './../assets/ph/ph3.jpg'
import p4 from './../assets/ph/ph4.jpg'
import p5 from './../assets/ph/ph5.jpg'
import p6 from './../assets/ph/ph6.jpg'
import p7 from './../assets/ph/ph7.jpg'
import p8 from './../assets/ph/ph8.jpg'
import p9 from './../assets/ph/ph9.jpg'
import p10 from './../assets/ph/ph10.jpg'
import p11 from './../assets/ph/ph11.jpg'
import p12 from './../assets/ph/ph12.jpg'
import p13 from './../assets/ph/ph13.jpg'
import p14 from './../assets/ph/ph14.jpg'
import p15 from './../assets/ph/ph15.jpg'
import p16 from './../assets/ph/ph16.jpg'
import p17 from './../assets/ph/ph17.jpg'
import p18 from './../assets/ph/ph18.jpg'

export const photos = [
  {
    src: p1,
    // title: 'Анатомия чувств',
    // description: 'Акварель, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p2,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p3,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p4,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p5,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p6,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p7,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p8,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p9,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p10,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p11,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p12,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p13,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p14,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p15,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p16,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p17,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
  {
    src: p18,
    // title: 'Гранат',
    // description: 'Карандаш, Бумага',
    width: 90,
    left: 5,
  },
]
